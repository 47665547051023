<template>
  <section class="invoice-add-wrapper">
    <order-form />
    <order-sidebar-add-new-customer />
  </section>
</template>

<script>

import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import {
  VBToggle,
} from 'bootstrap-vue'
import OrderForm from '@/views/apps/order/OrderForm.vue'
import orderStoreModule from '../OrderStoreModule'
import orderSidebarAddNewCustomer from '../OrderSidebarAddNewCustomer.vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    OrderForm,
    orderSidebarAddNewCustomer,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-order'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, orderStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const clients = ref([])
    const queryParams = {}
    store.dispatch('app-order/fetchClients', queryParams)
      .then(response => {
        clients.value = response.data
      })

    const salespersons = ref([])
    store.dispatch('app-order/fetchSalespersons')
      .then(response => { salespersons.value = response.data })

    const items = ref([])
    store.dispatch('app-ecommerce/fetchProducts')
      .then(response => { items.value = response.data })

    const itemFormBlankItem = {
      item: null,
      price: 0,
      quantity: 0,
      description: '',
    }

    const orderData = ref({
      client: null,
      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
      serviceDate: new Date(),
      salesperson: null,
      notes: 'Thank You!',
      paymentDetails: {
        paymentMethod: 'Cash',
        subtotal: 0,
        taxRate: 0.13,
        total: 0,
        less: 0,
      },
    })

    return {
      orderData,
      clients,
      salespersons,
      items,
      itemFormBlankItem,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.order-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-order.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
